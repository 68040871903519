import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import TextBreaks from '~/components/TextBreaks';
import Title from '~/components/Title';
import {
  Description,
  DescriptionWrapper,
  NoImgTopSection,
  Section,
  SmallText,
  media,
} from '~/styles';

const PrivacyPolicyPage: React.VFC<PageProps> = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO title="Privacy Policy" />
      <Layout>
        <NoImgTopSection>
          <Title title="policy.title" byKey isIntersection />
        </NoImgTopSection>
        <Section>
          <DescriptionWrapper showAnim isIntersection delay={0.9}>
            <PolicySection>
              <Description>{t('policy.sec1.description')}</Description>
            </PolicySection>
            <PolicySection>
              <SectionTitle>{t('policy.sec2.title')}</SectionTitle>
              <Description>{t('policy.sec2.description')}</Description>
            </PolicySection>
            <PolicySection>
              <SectionTitle>{t('policy.sec3.title')}</SectionTitle>
              <Description>
                {t('policy.sec3.description')}
                <PurposeWrapper>
                  <li>
                    <PorposeTitle>{t('policy.sec3.part1.title')}</PorposeTitle>
                    <SmallText>{t('policy.sec3.part1.description')}</SmallText>
                  </li>
                  <li>
                    <PorposeTitle>{t('policy.sec3.part2.title')}</PorposeTitle>
                    <SmallText>
                      {t('policy.sec3.part2.description')}
                      <ul>
                        <li>{t('policy.sec3.part2.purposes.item1')}</li>
                        <li>{t('policy.sec3.part2.purposes.item2')}</li>
                        <li>{t('policy.sec3.part2.purposes.item3')}</li>
                        <li>{t('policy.sec3.part2.purposes.item4')}</li>
                        <li>{t('policy.sec3.part2.purposes.item5')}</li>
                      </ul>
                    </SmallText>
                  </li>
                  <li>
                    <PorposeTitle>{t('policy.sec3.part3.title')}</PorposeTitle>
                    <SmallText>{t('policy.sec3.part3.description')}</SmallText>
                  </li>
                </PurposeWrapper>
              </Description>
            </PolicySection>
            <PolicySection>
              <SectionTitle>{t('policy.sec4.title')}</SectionTitle>
              <Description>{t('policy.sec4.description')}</Description>
            </PolicySection>
            <PolicySection>
              <SectionTitle>{t('policy.sec5.title')}</SectionTitle>
              <Description>{t('policy.sec5.description')}</Description>
            </PolicySection>
            <PolicySection>
              <SectionTitle>{t('policy.sec6.title')}</SectionTitle>
              <Description>{t('policy.sec6.description')}</Description>
            </PolicySection>
            <PolicySection>
              <SectionTitle>{t('policy.sec7.title')}</SectionTitle>
              <Description>{t('policy.sec7.description')}</Description>
            </PolicySection>
            <PolicySection>
              <SectionTitle>{t('policy.sec8.title')}</SectionTitle>
              <Description>{t('policy.sec8.description')}</Description>
              <SmallText>
                <Trans i18nKey="policy.sec8.contact" components={TextBreaks} />
              </SmallText>
            </PolicySection>
          </DescriptionWrapper>
        </Section>
      </Layout>
    </>
  );
};

const PolicySection = styled.div`
  display: grid;
  gap: 40px;
  width: 100%;

  line-height: 2;

  ${media.mdUp} {
    font-size: 24px;
  }

  ${media.md} {
    font-size: 20px;
    max-width: 720px;
  }

  ${media.smDown} {
    font-size: 16px;
  }
`;

const SectionTitle = styled.h4`
  text-align: center;
  font-weight: normal;
`;

const PurposeWrapper = styled.ul`
  display: grid;
  gap: 20px;
  margin-top: 20px;
`;

const PorposeTitle = styled.h5`
  line-height: 2;
  font-weight: normal;

  ${media.smUp} {
    font-size: 16px;
  }

  ${media.smDown} {
    font-size: 14px;
  }
`;

export default PrivacyPolicyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
